@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body {
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #2a4c4b;
  background: radial-gradient(
        85.53% 81.9% at 66.11% 118.68%,
        rgba(6, 40, 197, 0.4) 0%,
        rgba(10, 42, 187, 0.0929615) 50.83%,
        rgba(12, 43, 184, 0) 85.73%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    radial-gradient(
        184.7% 165.26% at 110.65% 159.49%,
        rgba(134, 0, 197, 0.486) 20.45%,
        rgba(85, 40, 201, 0.9) 37.74%,
        rgba(149, 27, 223, 0.567) 53.48%,
        rgba(249, 66, 132, 0.54) 64.05%,
        rgba(92, 0, 165, 0.891) 88.23%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  font-family: "ProximaNova";
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Proxima Nova";
  src: url(./assets/fonts/proximaNova/ProximaNova-Regular.otf)
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url(./assets/fonts/proximaNova/ProximaNovaBold.otf) format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url(./assets/fonts/proximaNova/ProximaNovaBlack.otf) format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNova";
  font-weight: 700;
  src: local("ProximaNova"),
    url(./assets/fonts/proximaNova/ProximaNova-Semibold.ttf) format("truetype");
}

@font-face {
  font-family: "ProximaNova";
  font-weight: 800;
  src: local("ProximaNova"),
    url(./assets/fonts/proximaNova/ProximaNova-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "ProximaNova";
  font-weight: 900;
  src: local("ProximaNova"),
    url(./assets/fonts/proximaNova/ProximaNova-Extrabld.ttf) format("truetype");
}

@font-face {
  font-family: "ProximaNova";
  src: local("ProximaNova"),
    url(./assets/fonts/proximaNova/ProximaNova-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "proxima-nova-extra-condensed";
  font-weight: 800;
  src: local("ProximaNova"),
    url(./assets/fonts/proximaNova/ProximaNovaExCn-Extrabld.ttf)
      format("truetype");
}

.font-link {
  font-family: "Barlow", sans-serif;
}

.font-Mont {
  font-family: "Montserrat", sans-serif;
}

.flex-center-row {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.flex-center-col {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: "10px";
  box-shadow: "inset 0 0 2px #b161ce";
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b161ce;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b161ce;
}

:root {
  --clr-black: #000;
  --clr-white: #fff;
  --clr-purple-lt: #5e14a8;
}
